<template>
  <!-- TODO: check order="1" works like 'stateless' in the original code https://vuetifyjs.com/en/getting-started/upgrade-guide/#setup -->
  <v-navigation-drawer
    :model-value="isSidePanelActive"
    absolute
    temporary
    location="right"
    :scrim="false"
    width="900"
  >
    <v-container>
      <div class="text-right">
        <v-btn
          icon="mdi-close"
          size="small"
          variant="text"
          slim
          @click="$emit('close:side-panel')"
        >
          <v-icon theme="dark"> mdi-close </v-icon>
        </v-btn>
      </div>
      <v-expansion-panels
        v-model="panel"
        variant="accordion"
        flat
        multiple
      >
        <v-expansion-panel>
          <v-expansion-panel-title>Result Details</v-expansion-panel-title>
          <v-divider class="mx-5 mb-3" />
          <v-expansion-panel-text>
            <!-- Dan: This isnt yaml i know.. but this is what works... -->
            <KustoMonacoEditor
              v-if="panelText"
              style="
                width: 100%;
                height: 80vh;
                resize: vertical;
                overflow: hidden;
              "
              :options="options"
              :value="panelText"
              language="yaml"
              name="detailsView"
            />
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import yaml from 'js-yaml';
import { isEmptyValue } from '@/renderer/utils';

import { reactive, computed, onMounted, ref } from 'vue';
import { eventBus } from '@/main';

// Data
const panel = reactive([0, 1]);
const panelText = ref<string>('');
const options = reactive({
  minimap: {
    enabled: false,
  },
  automaticLayout: true,
  readOnly: true,
});

// Props
const props = defineProps<{
  data: {
    type: String;
    required: false;
  };
  isSidePanelActive: Boolean;
}>();

// Computed
const filteredData = computed(() => {
  if (null == props.data) {
    return {};
  }

  return Object.keys(props.data).reduce((obj, v) => {
    if (!isEmptyValue(props.data[v])) {
      obj[v] = props.data[v];
    }
    return obj;
  }, {});
});

// Methods
const yamlText = (data) => {
  if (!(typeof data === 'object')) {
    return data;
  }

  //data = data.replace(/[\x00-\x08\x0b-\x1f\x7f-\x9f]/gu,'');
  return yaml.dump(data, {
    replacer: (key, value) => {
      if (typeof value === 'string') {
        return value.replace(/[\x00-\x08\x0b-\x1f\x7f-\x9f]/gu, '');
      }
      return value;
    },
  });
};

// Mounted
onMounted(() => {
  eventBus.$on('update:detail-side-panel', (payload) => {
    panelText.value = JSON.stringify(payload, null, 4);
  });
});
//
//export default {
//  name: "DetailSidePanel",
//  props: {
//    data: {
//      type: Object || String || null,
//      required: false,
//      default: null,
//    },
//    isSidePanelActive: Boolean,
//  },
//  emits: ["close:side-panel"],
//  data: () => ({
//    panel: [0, 1],
//  }),
//  computed: {
//    filteredData() {
//      if (null == this.data) {
//        return {};
//      }
//
//      return Object.keys(this.data).reduce((obj, v) => {
//        if (!isEmptyValue(this.data[v])) {
//          obj[v] = this.data[v];
//        }
//        return obj;
//      }, {});
//    },
//  },
//  mounted() {},
//  methods: {
//    yamlText: (data) => {
//      if (!(typeof data === "object")) {
//        return data;
//      }
//
//      //data = data.replace(/[\x00-\x08\x0b-\x1f\x7f-\x9f]/gu,'');
//      return yaml.dump(data, {
//        replacer: (key, value) => {
//          if (typeof value === "string") {
//            // eslint-disable-next-line no-control-regex
//            return value.replace(/[\x00-\x08\x0b-\x1f\x7f-\x9f]/gu, "");
//          }
//          return value;
//        },
//      });
//    },
//  },
//};
</script>

<style>
dt {
  margin-top: 5px;
  grid-column-start: 1;
  font-weight: 500;
  overflow-wrap: anywhere;
}

dd {
  margin-top: 5px;
  padding-left: 10px;
  grid-column-start: 2;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}

dl {
  grid-template-columns: 250px auto;
  display: grid;
  font-size: 0.9rem;
}

h3 {
  margin-top: 2rem;
  font-weight: 400;
}
</style>
