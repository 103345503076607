<template>
  <div>
    <v-combobox
      label="Cluster"
      v-model="cluster"
      :items="getClusters"
      :rules="[(v) => !!v || 'Cluster is required']"
      @update:model-value="emit('update:cluster', props.cluster)"
    />
    <v-combobox
      label="Database"
      v-model="database"
      :items="getDatabases"
      :hide-no-data="true"
      :rules="[(v) => !!v || 'Database is required']"
      @update:model-value="emit('update:database', props.database)"
    />
  </div>
</template>

<script setup lang="ts">
import clusterGroups from '@/config/kustoClusters';
import { useStore } from '@/store/store';
import { computed, ref } from '@vue/reactivity';
import { onMounted } from 'vue';
// Store
const store = useStore();
// Data
const cluster = ref<string>('');
const database = ref<string>('');
// Props
const props = defineProps<{
  cluster: string;
  database: string;
}>();
// Emitters
const emit = defineEmits<{
  update: [cluster?: String, database?: String];
}>();
// Computed
const getKustoClusters = computed(() => {
  return store.getters['engagement/getKustoClusters'];
});
// Lifecycle
onMounted(() => {
  cluster.value = props.cluster;
  database.value = props.database;
});

const getClusters = computed(() => {
  let items: { header: string; clusters?: string[]; database?: string[] }[] =
    [];
  let kc = getKustoClusters || [];
  if (Object.keys(kc.value).length === 0) {
    return items;
  }
  kc.value.forEach((v) => {
    // TODO: Dan - get headers working again later
    //items.push({
    //  header: v.name,
    //});
    items.push(...v.clusters);
  });
  return items;
});

const getDatabases = computed(() => {
  let kc = getKustoClusters || [];
  return (
    kc.value.find((clusterGroup) =>
      clusterGroup.clusters.includes(props.cluster),
    )?.databases ?? []
  );
});
</script>

<style></style>
