<template>
  <v-navigation-drawer
    v-model="localTreeHidden"
    width="380"
  >
    <div
      class="pl-2"
      style="max-width: 100%; min-width: 700px; white-space: nowrap"
    >
      <NewQueryButton
        tile
        @update:active-uuid="(uuid: string) => (activeUuid = uuid)"
      />
      <v-divider
        vertical
        style="height: 18px"
      />
      <v-btn
        variant="text"
        icon
        tile
        title="Reload templates"
        @click="onClickReloadTemplates"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-divider
        vertical
        style="height: 18px"
      />
      <v-btn
        :disabled="selected.length === 0"
        variant="text"
        icon
        tile
        title="Remove selected"
        @click="onClickRemoveSelected"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        :disabled="getRootDisplayComponents?.length === 0"
        variant="text"
        icon
        tile
        title="Remove all"
        @click="onClickRemoveAll"
      >
        <v-icon>mdi-delete-sweep</v-icon>
      </v-btn>
      <v-menu>
        <template #activator="{ props }">
          <v-btn
            prepend-icon="mdi-ghost"
            variant="flat"
            v-bind="props"
            @click="onSetEngagement()"
          >
            {{ getEngagement || 'Engagement' }}
          </v-btn>
        </template>
      </v-menu>
    </div>
    <v-divider />
    <ConfirmDialog ref="confirmDialogue" />
    <EngagementDialog />
    <v-treeview
      :items="getRootDisplayComponents"
      :open-on-click="false"
      :activated="active"
      @click:select="clickSelected"
      @update:activated="updateActive"
      @click.right.prevent="handleRightClick"
      activatable
      active-class="v-treeview-node--active active-query"
      active-strategy="single-independent"
      class="override-treeview"
      density="compact"
      item-value="componentUuid"
      loading-icon="mdi-timer-sand"
      ref="componentTreeView"
      select-strategy="independent"
      selectable
      slim
      v-model:opened="opened"
      v-model:selected="selected"
    >
      <template
        #prepend="{
          item,
          isActive,
        }: {
          item: DisplayComponentItems;
          isActive: boolean;
        }"
      >
        <span
          v-if="item.state.isExecuting"
          :style="{ paddingRight: '5px' }"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            :size="16"
            :width="2"
          />
        </span>
        <v-icon
          v-else-if="item.state.error"
          color="error"
          >mdi-alert</v-icon
        >
        <v-badge
          v-else-if="item.state.rowCount !== null && item.state.rowCount >= 0"
          :color="
            item.state.isVisited
              ? 'grey'
              : item.state.rowCount === 0
                ? 'warning'
                : 'info'
          "
          :content="
            item.state.rowCount > 9 ? '9+' : item.state.rowCount.toString()
          "
        >
          <v-icon
            :class="
              isActive
                ? isInvestigation(item)
                  ? 'primary--text investigation-node'
                  : 'primary--text'
                : ''
            "
            :color="isInvestigation(item) ? '#7852A9' : ''"
          >
            {{
              // if opened has the item, then it is open
              isOpened(item) ? 'mdi-folder-open' : 'mdi-folder'
            }}
          </v-icon>
        </v-badge>
        <template v-else>
          <v-icon
            :style="isInvestigation(item) ? 'opacity: 1.0' : 'opacity: 0.5'"
            :class="isActive ? 'primary--text' : ''"
            :color="isInvestigation(item) ? '#7852A9' : ''"
          >
            {{
              isInvestigationFolder(item)
                ? isOpened(item)
                  ? 'mdi-folder-open'
                  : 'mdi-folder-star'
                : isOpened(item)
                  ? 'mdi-folder-open'
                  : 'mdi-folder'
            }}
          </v-icon>
        </template>
      </template>

      <template #title="{ item }">
        <span>
          {{ isDraft(item) ? '[draft] ' : isNew(item) ? '[new] ' : '' }}
        </span>
        <span
          :class="isNew(item) ? 'font-weight-medium' : ''"
          :color="isNew(item) ? '#7852A9' : ''"
        >
          <template v-if="getTimeStampFromTitle(item.title)">
            <v-chip-group selected-class="text-primary">
              <v-chip
                size="small"
                density="compact"
                label
                style="color: orange"
              >
                <v-icon
                  icon="mdi-timer-edit"
                  start
                ></v-icon>
                {{ getTimeStampFromTitle(item.title)?.front }}
              </v-chip>
              <v-chip
                size="small"
                density="compact"
                label
                :class="getTimeStampFromTitle(item.title)?.color"
              >
                <v-tooltip
                  activator="parent"
                  location="bottom"
                  >{{ getTimeStampFromTitle(item.title)?.datetime }}</v-tooltip
                >
                <v-icon
                  icon="mdi-clock"
                  start
                ></v-icon>
                TS
              </v-chip>
            </v-chip-group>
            {{ getTimeStampFromTitle(item.title)?.middle }}
          </template>
          <template v-else>
            {{ item.title }}
          </template>
        </span>
      </template>
    </v-treeview>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import { eventBus } from '@/main';
import {
  createNewQueryComponent,
  createNewTemplateQueryComponent,
} from '@/renderer/displayComponent';
import NewQueryButton from '@/components/NewQueryButton.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import { useRouter, useRoute } from 'vue-router';

import { reactive, ref, computed, watch, onMounted, toRefs } from 'vue';
import { useStore } from '@/store/store';
import { VTreeview } from 'vuetify/labs/VTreeview';
import { DisplayComponentItems } from '@/store/modules/stateInit';
import EngagementDialog from './EngagementDialog.vue';

// Store
const store = useStore();
// Data
const componentTreeView = ref<VTreeview>();
const confirmDialogue = ref(null);
const activeUuid = ref('');
const selected = ref<string[]>([]);
const opened = ref<string[]>([]);
const router = ref(useRouter());
const route = ref(useRoute());
const dateRe = ref(
  /(?<front>.*)\:\s(?<middle>.*),\s(?<datetime>\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d+Z)/,
);
// Props
const props = defineProps<{
  hideTreeView: boolean;
}>();
const { hideTreeView } = toRefs(props);
// Computed
const localTreeHidden = computed({
  get: () => hideTreeView.value,
  set: (val) => console.log('localTreeHidden', val),
});

const getEngagement = computed(() => {
  return store.getters['engagement/getEngagement'];
});
const onSetEngagement = () => {
  eventBus.$emit('create:set-engagement', {
    message: 'Setting engagement.',
    color: 'info',
  });
};
const active = computed(() => {
  return [activeUuid.value];
});

const isNew = computed(() => (item) => {
  return (
    item.state.rowCount !== null &&
    !item.state.isVisited &&
    item.state.error === null
  );
});

const isDraft = computed(() => (item) => {
  return (
    item.state.rowCount === null &&
    !item.state.isExecuting &&
    item.state.error === null
  );
});

const isInvestigation = computed(() => (item) => {
  const isInvestigation =
    item.state?.investigationView === true ||
    String(item?.title).startsWith('Investigation');
  return isInvestigation;
});

const isInvestigationFolder = computed(() => (item) => {
  const isInvestigation =
    String(item?.title).startsWith('Investigation') && item.params?.querySet;
  return isInvestigation;
});

const getAllDisplayComponents = computed(() => {
  return store.getters['displayComponent/getAllDisplayComponents'];
});

// Getters
const isComponent = (uuid: string) => {
  return store.getters['displayComponent/isComponent'](uuid);
};
// Actions
const updateComponentState = ({
  uuid,
  isVisited,
}: {
  uuid: string;
  isVisited: boolean;
}) => {
  store.dispatch('displayComponent/updateComponentState', { uuid, isVisited });
};

const removeSelectedDisplayComponents = ({
  uuidArray,
}: {
  uuidArray: string[];
}) => {
  store.dispatch('displayComponent/removeAllDisplayComponents', { uuidArray });
};

// Methods
const getTimeStampFromTitle = (title: string) => {
  const re = dateRe.value.test(title);
  if (re) {
    const {
      front,
      middle,
      datetime,
    }: { front: string; middle: string; datetime: string } =
      dateRe.value.exec(title)?.groups;
    if (datetime) {
      let color: string = '';
      if (front.includes('Timeline')) {
        color = 'bg-cyan-accent-2';
      }
      return { front, middle, datetime, color };
    }
  }
};
const clickSelected = (ev) => {
  // We use independent selection strategy but select/unselect children
  // to implement the desired selection behaviour
  const ch = store.getters['displayComponent/getRecursiveChildComponents'](
    ev.id,
  );
  if (ev.value === true) {
    ch.forEach((uuid) => {
      if (!selected.value.includes(uuid)) {
        selected.value.push(uuid);
      }
    });
  } else if (ev.value === false) {
    ch.forEach((uuid) => {
      const i0 = selected.value.indexOf(uuid);
      selected.value = selected.value.splice(i0, 1);
    });
  }
};
const handleRightClick = (node) => {
  // TODO: in future add context menu for delete - scv
};
const updateActive = (newValue: string[]) => {
  if (newValue.length === 1) {
    const uuid = newValue[0];
    updateComponentState({ uuid: uuid, isVisited: true });
    if (uuid !== activeUuid.value) {
      router.value.push({ name: 'OpenTriage', params: { uuid: uuid } });
    }
    activeUuid.value = uuid;
  }
  // update isNavClosed if we have no children
};

const updateSelected = (newValue: string[]) => {
  selected.value = newValue;
};

const isOpened = (item: DisplayComponentItems) => {
  return opened.value.includes(item.componentUuid);
};

const onClickRemoveSelected = () => {
  removeSelectedDisplayComponents({ uuidArray: selected.value });

  if (activeUuid.value && !isComponent(activeUuid.value)) {
    router.replace('/');
  }
};

const onClickRemoveAll = async () => {
  const ok = await confirmDialogue.value?.show({
    title: 'Close All Queries',
    message: 'Are you sure you want to close all queries?',
    okButton: 'Confirm',
  });
  if (!ok) {
    return;
  }
  await doRemoveAll();
};

const doRemoveAll = async () => {
  const all = getAllDisplayComponents.value;
  removeSelectedDisplayComponents({ uuidArray: all });
  if (activeUuid.value && !isComponent(activeUuid.value)) {
    router.replace('/');
  }
};

const onClickNewQuery = function () {
  createNewQueryComponent('New query');
};

const onClickNewView = function (queryTemplate) {
  createNewTemplateQueryComponent(
    queryTemplate.summary,
    queryTemplate,
    queryTemplate.getDefaultParams(),
    null,
    true,
  );
};

const onClickReloadTemplates = async () => {
  await store.dispatch('queries/reloadQueries');
};

const loadAllDisplayComponents = async () => {
  await store.dispatch('displayComponent/loadAllDisplayComponents');
};

const getParentDisplayComponent = (uuid: string) => {
  return store.getters['displayComponent/getParentDisplayComponent'](uuid);
};
const getRootDisplayComponents = computed(() => {
  return store.getters['displayComponent/getRootDisplayComponents'];
});
// Watch
watch(route.value, (to, from) => {
  activeUuid.value = to.params.uuid;
});

// Watch for changes in the prop and update local state
watch(
  () => props.hideTreeView,
  (newValue) => {
    localTreeHidden.value = newValue;
  },
);

// Mounted
onMounted(() => {
  eventBus.$on('new:display-component', ({ uuid }) => {
    const parent = getParentDisplayComponent(uuid);
    // When adding new leaf open parent
    if (parent && opened.value.indexOf(parent) === -1) {
      opened.value.push(parent);
    }
  });
  eventBus.$on('update:kusto-results', ({ uuid }) => {
    // Results were complete on the active component
    if (uuid === activeUuid.value) {
      updateComponentState({ uuid: uuid, isVisited: true });
    }
  });
  eventBus.$on('app:loaded', () => {
    loadAllDisplayComponents();
  });
  eventBus.$on('close-all:kusto-results', doRemoveAll);
  loadAllDisplayComponents();
});
</script>

<style>
.v-list-item-title {
  cursor: pointer;
  white-space: break-spaces;
}

.text-primary.investigation-node {
  color: #7852a9 !important;
}
</style>
