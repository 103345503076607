<template>
  <v-container
    fluid
  >
    <div :class="isTreeOpen ? 'tim-main-view-2' : 'tim-main-view-2'">
      <keep-alive :max="100">
        <component
          :is="loadComponent"
          :key="`${componentName}-${uuid}`"
          :name="`${componentName}-${uuid}`"
          :uuid="uuid"
        />
      </keep-alive>
    </div>
    <DetailSidePanel
      v-if="detailPanelData"
      :data="detailPanelData"
      :is-side-panel-active="showDetailSidePanel"
      @close:side-panel="showDetailSidePanel = false"
    />
    <TaggedEventDialog />
    <TagObjectDialog />
    <TagIndicatorDialog />
    <SuppressionDialog />
    <SpectreAddDialog />
  </v-container>
</template>

<script setup lang="ts">
import DetailSidePanel from '@/components/DetailSidePanel.vue';
import { eventBus } from '@/main';
import TaggedEventDialog from '@/components/TagEventDialog.vue';
import TagObjectDialog from '@/components/TagObjectDialog.vue';
import TagIndicatorDialog from '@/components/TagIndicatorDialog.vue';
import SuppressionDialog from '@/components/SuppressionDialog.vue';
import SpectreAddDialog from '@/components/SpectreAddDialog.vue';
import TemplateQueryResult from '@/components/TemplateQueryResult.vue';
import KustoQueryResult from '@/components/KustoQueryResult.vue';
import SavedQueryResult from '@/components/SavedQueryResult.vue';
import QuerySetResults from '@/components/QuerySetResults.vue';

import { ref, computed, onBeforeMount } from 'vue';
import { useStore } from '@/store/store';
import router from '@/router';
import type { Component } from 'vue';

// Store
const store = useStore();

// Data
const showDetailSidePanel = ref(false);
const detailPanelData = ref(null);
const debugNode = ref(null);
const treeViewOpen = ref(true);
// ComponentReg Type/Objects
// TODO: this is basic but works for now..
type ComponentReg = {
  TemplateQueryResult: Component;
  KustoQueryResult: Component;
  SavedQueryResult: Component;
  QuerySetResults: Component;
};
const registeredComponent: ComponentReg = {
  TemplateQueryResult: TemplateQueryResult,
  KustoQueryResult: KustoQueryResult,
  SavedQueryResult: SavedQueryResult,
  QuerySetResults: QuerySetResults,
};
// Props
const props = defineProps({
  uuid: {
    type: String,
    required: true,
  },
});

// Computed
const componentName = computed(() => {
  if (isComponent(props.uuid)) {
    let componentName: string = getComponentName(props.uuid);
    return componentName;
  }
  return null;
});
const loadComponent = computed(() => {
  if (isComponent(props.uuid)) {
    let componentName: string = getComponentName(props.uuid);
    return registeredComponent[componentName];
  }
  return null;
});

const isTreeOpen = computed(() => {
  return process.env.VUE_APP_TREE_MODE === 'open';
});

// Getters
const isComponent = (uuid: string) => {
  return store.getters['displayComponent/isComponent'](uuid);
};
const getComponentName = (uuid: string) => {
  return store.getters['displayComponent/getComponentName'](uuid);
};

// Methods
const onShowDetailSidePanel = (data) => {
  showDetailSidePanel.value = true;
  detailPanelData.value = data;
};

const onUpdateDetailSidePanel = (data) => {
  if (showDetailSidePanel.value) {
    detailPanelData.value = data;
  }
};

// BeforeMount
onBeforeMount(() => {
  eventBus.$on('show:detail-side-panel', onShowDetailSidePanel);
  eventBus.$on('update:detail-side-panel', onUpdateDetailSidePanel);

  //console.log("isComponent=", isComponent(props.uuid))
  //if (!isComponent(props.uuid)) {
  //  router.replace('/');
  //}
});
</script>

<style>
.ag-theme-balham .ag-details-row {
  padding: 0;
}
.ag-theme-balham .ag-root-wrapper {
  width: 100%;
}
.ag-body-viewport {
  overflow-y: scroll !important;
}

.ag-theme-balham .ag-grade-tp {
  background-color: #fbbbb9;
}

.ag-theme-balham .ag-grade-bp {
  background-color: #c2dfff;
}

.ag-theme-balham .ag-grade-fp {
  background-color: #99c68e;
}
</style>
